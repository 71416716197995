html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    'Helvetica Neue', sans-serif;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('https://firebasestorage.googleapis.com/v0/b/portfolio-377f4.appspot.com/o/images%2Fheader_2024.webp?alt=media&token=e866e83a-726f-4c4e-93e8-8ff1c6ab5bb9')
      no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hero .subtitle {
  line-height: 1.5;
}

.hero-body .navbar > .container {
  display: flex;
  justify-content: center;
}

.navbar > .container {
  display: flex;
  flex-flow: column;
}

.navbar-burger {
  color: #fff;
  margin: 0;
}

.navbar-brand {
  text-align: center;
}
.navbar-menu {
  text-align: center;
}

.navbar-menu.is-active {
  background-color: inherit !important;
}

/***************
* Jump to Top  *
****************/
#toTop {
  height: 50px;
  width: 50px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: rgba(31, 44, 108, 0.65);
  color: white;
  cursor: pointer;
  border-radius: 50%;
  font-size: 2rem;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
  text-align: center;
}

#toTop a {
  color: white;
}

#toTop:hover {
  background-color: #43485c;
}

/****************
* Main Content *
****************/
main {
  padding-top: 2rem;
  color: #3c4172;
  font-family: 'Poppins', sans-serif;
}

main .section-dark {
  background-color: #f0f3f5;
  padding: 60px 0px;
}

main .section-dark.resume {
  height: 400px;
  padding-top: 100px;
}

@media (max-width: 599px) {
  main .section-dark.resume {
    padding-top: 75px;
  }
}

main .section-dark.resume .title {
  padding: 20px;
}

main .section-dark.resume button {
  border: 2px solid #43485c;
  background: white;
  color: #43485c;
  height: 50px;
  width: 250px;
  font-size: 1rem;
  text-transform: uppercase;
}

main .section-dark.resume .fa-download {
  color: #1f2c6c;
}

main .section-dark.my-work {
  padding: 120px;
}

@media (max-width: 599px) {
  main .section-dark.my-work {
    padding: 75px 20px;
  }
}

main .section-dark.my-work .work-item {
  margin-bottom: 1rem;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

main .section-dark.my-work .work-item:hover {
  -webkit-transform: scale(1.055) translateY(-2px);
  transform: scale(1.055) translateY(-2px);
  -webkit-box-shadow: 0 2px 25px 0 rgba(30, 30, 30, 0.1);
  box-shadow: 0 2px 25px 0 rgba(30, 30, 30, 0.1);
}

main .section-light {
  padding: 30px;
}

main .section-light.about-me {
  padding-bottom: 100px;
}

main .section-light.about-me .is-larger {
  font-size: 1.2rem;
}

@media (max-width: 599px) {
  main .section-light.about-me .about-links {
    text-align: center;
  }
}

main .section-light.about-me .right-image img {
  border-radius: 11px;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

@media (max-width: 599px) {
  main .section-light.about-me .right-image img {
    margin: auto;
  }
}

main .section-light.skills {
  padding: 100px;
}

@media (max-width: 599px) {
  main .section-light.skills {
    padding: 50px 20px 40px 20px;
    padding-bottom: 20px;
  }
}

main .section-light.skills h1 {
  padding: 10px;
}

main .section-light.contact {
  padding: 200px;
}

main .section-light.contact button {
  width: 100%;
  background-color: #43485c;
  color: white;
  height: 50px;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 599px) {
  main .section-light.contact {
    padding: 60px 20px;
  }
}

main .section-color.services {
  padding: 100px;
  background-color: #43485c;
  color: white;
}

main .section-color.services .title,
main .section-color.services .subtitle {
  color: white;
}

main .section-color.services i {
  font-size: 3rem;
  padding: 20px;
}

main .section-title {
  color: #43485c;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 2px;
  padding-bottom: 30px;
}

.footer {
  bottom: 0;
  width: 100vw;
  padding: 0;
  height: 80px;
  line-height: 25px;
  text-align: center;
  background: #1f2c6c;
  color: whitesmoke;
  padding-top: 2rem;
}

.footer a {
  padding: 0rem 1rem 1rem 1rem;
  color: lightgrey;
}

.footer a i {
  font-size: 1.5rem;
  padding: 0px 10px;
}

.footer a .nav-item {
  color: whitesmoke;
}

.fa-download {
  color: #43485c;
}

#toTop {
  height: 50px;
  width: 50px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: rgba(31, 44, 108, 0.65);
  color: white;
  cursor: pointer;
  border-radius: 50%;
  font-size: 2rem;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

#toTop a {
  color: white;
}

#toTop:hover {
  background-color: #43485c;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(245, 245, 245, 0);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #43485c;
}

body.preloader-site {
  overflow: hidden;
}

.preloader-wrapper {
  height: 100%;
  width: 100%;
  background: #1f2c6c;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
}

.preloader-wrapper .preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 120px;
}

#about-me .about-links {
  text-align: center;
  flex-wrap: wrap;
}
